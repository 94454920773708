import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from "./auth.service";
import { database } from 'firebase';

const apiURL = 'https://weather.ls.hereapi.com/weather/1.0/report.json?product=observation';
const apiKey = 'GTnWVgztAD3mVxPQ1RJfp1IQT7lKlY3hIOTx2LKPbjA';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(private http: HttpClient, public authService: AuthService) { }

  getWeatherInfo(lat:any, long:any){
    return this.http.get<any>(apiURL+'&latitude='+lat+'&longitude='+long+'&oneobservation=true&language=tr-TR&apiKey='+apiKey);
  }
}
