import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalComponent } from './modal/modal.component';
import { CountdownModule } from 'ngx-countdown';



@NgModule({
  declarations: [SidebarComponent, HeaderComponent, ModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    CountdownModule
  ],
  exports: [SidebarComponent, HeaderComponent],
  entryComponents: [ModalComponent]
})
export class SharedModule { }
