import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AuthService } from "./auth.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { catchError, tap, map } from 'rxjs/operators';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { Observable, of, throwError } from 'rxjs';
import { Tasks } from 'src/app/interfaces/tasks';
import { equal } from 'assert';
import { WeatherService } from './weather.service';
import { TasksService } from './tasks.service';



@Injectable({
  providedIn: 'root'
})
export class ApiService {
  menuData: AngularFireList<any>; 
  menuContentData: AngularFireObject<any>;

  lastestDronesListingData: AngularFireList<any>;
  droneDetailData: AngularFireList<any>;
  droneContentData: AngularFireObject<any>;

  stationsTypes: AngularFireList<any>;

  stationsList: AngularFireList<any>;
  stationDetailData: AngularFireList<any>;
  stationDataIs: AngularFireObject<any>;

  tasksList: AngularFireList<any>;
  activeTasksList: AngularFireList<any>;
  taskDetailData: AngularFireList<any>;
  taskDetailIs: AngularFireObject<any>;

  portDataIs: AngularFireObject<any>;
  turbineDataIs: AngularFireObject<any>;

  latestReportsData: AngularFireList<any>;
  reportDetailData: AngularFireList<any>;
  emergencyData: AngularFireList<any>;
  latestEmergencyData: AngularFireList<any>;
  reportList: AngularFireList<any>;
  reportDetailIs: AngularFireObject<any>;

  hasScheduled: any;
  taskRadius:any;

  reportStationIs: any;
  reportTaskIs: any;
  reportDroneIs: any;
  reportWeatherIs: any;

  systemLoaded = false;

  
  public apiURL = 'http://localhost:3001';
  //public apiURL = 'http://api.auraarge.com/api';

  constructor(private http: HttpClient, public authService: AuthService, private db: AngularFireDatabase, private fs: AngularFirestore, private wapi: WeatherService) { }

  private actionHeaders = {
    headers: new HttpHeaders({'Sender': 'AIRCON', 'UID': this.authService.getUID})
  };

  
 
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  /*public getMenu(): Observable<Menu>{
    const getMenuHeaders = new HttpHeaders({'Sender': 'AIRCON', 'UID': this.authService.getUID});
    return this.http.get<Menu>(this.apiURL+'/menu', {getMenuHeaders}).pipe(
      tap((m: Menu) => console.log(`added menu w/ id=${m.id}`)),
      catchError(this.handleError<Menu>('getMenu'))
    );;
  }*/

  getUserMenu(){
    this.menuData = this.db.list('menu');
    return this.menuData;
  }

  getLatestDrones(){
    this.lastestDronesListingData = this.db.list('drones', ref => ref.orderByChild('lastActivity/date').limitToLast(6));
    return this.lastestDronesListingData;
  }

  getDroneDetail(drone:any){
    
    this.droneDetailData = this.db.list('drones', ref => ref.orderByChild('id').equalTo(drone).limitToFirst(1));
    return this.droneDetailData;
  }

  getDroneDetailFor(drone:any){
    this.droneContentData = this.db.object('drones/' + drone);
    return this.droneContentData;
  }

  getTasksFor(station:any){
    this.tasksList = this.db.list('tasks', ref => ref.orderByChild('stationId').equalTo(station));
    return this.tasksList;
  }

  getActiveTasks(){
    this.activeTasksList = this.db.list('tasks', ref => ref.orderByChild('isActive').equalTo(true).limitToLast(5));
    return this.activeTasksList;
  }

  getTaskDetail(task: any){
    var theTaskIs;
    if(task['taskId'] !=null || task['taskId'] != undefined){
      theTaskIs = task['taskId'];
    }else{
      theTaskIs = task;
    }
    this.taskDetailData = this.db.list('tasks', ref => ref.orderByChild('id').equalTo(theTaskIs));
    return this.taskDetailData;
  }

  getTaskDetailFor(theId:any){
    this.taskDetailIs = this.db.object('tasks/' + theId);
    return this.taskDetailIs;
  }

  runTask(theId:any){
    this.taskDetailIs = this.db.object('tasks/' + theId);
    this.taskDetailIs.update({
      isActive:true
    }).catch(e => {
      console.log(e);
      return false;
    });
    
    return true;
  }

  delTask(theId:any){
    this.taskDetailIs = this.db.object('tasks/' + theId);
    this.taskDetailIs.remove().catch(e =>{
      console.log(e);
      return false;
    });
    return true;
  }

  addTask(task: any){
    if(task.taskPlanType == 1){
      this.hasScheduled = true;
    }else if(task.taskPlanType == 2){
      this.hasScheduled = false;
      task.scheduledDays = [8];
    }
    if(task.taskType == 2){
      this.taskRadius = 20
    }else{
      this.taskRadius = 0
    }
    
    this.tasksList = this.db.list('tasks');
    let theIdOfTask = new Date().getMilliseconds();
    this.tasksList.push({
      ///$key: theIdOfTask,
      id: theIdOfTask,
      name: task.taskName,
      type: task.taskType,
      hasScheduled: this.hasScheduled,
      issued: task.taskIssued,
      isActive: false,
      desc: task.taskDesc,
      routes: task.theRoute,
      stationId: Number(task.station),
      droneId: task.theDrone,
      portId: task.thePort,
      scheduleDays: task.scheduledDays,
      scheduleTime: task.taskScheduleTime,
      radius: this.taskRadius,
      minAltitude: 5,
      maxAltitude: 45

    }).catch( e => {
      console.log(e);
      return false
    });
    return true;
  }

  getStationsFor(type:any){
    this.stationsList = this.db.list('stations', ref => ref.orderByChild('type').equalTo(type));
    return this.stationsList;
  }

  getStationDetail(st:any){
    this.stationDetailData = this.db.list('stations', ref => ref.orderByChild('id').equalTo(st).limitToFirst(1));
    return this.stationDetailData;
  }

  getStationDetailFor(st:any){
    this.stationDataIs = this.db.object('stations/' + st);
    return this.stationDataIs;
  }

  getStationPortDataFor(st:any, pt:any){
    this.portDataIs = this.db.object('stations/' + st +'/ports/' + pt);
    return this.portDataIs;
  }

  getStationTurbineDataFor(st:any, pt:any){
    this.turbineDataIs = this.db.object('stations/' + st +'/turbines/' + pt);
    return this.turbineDataIs;
  }

  getStationTypes(){
    this.stationsTypes = this.db.list('stationsType', ref => ref.orderByChild('id'));
    return this.stationsTypes;
  }

  getReportDetail(report:any){
    this.stationDetailData = this.db.list('reports', ref => ref.orderByChild('id').equalTo(report));
    return this.stationDetailData;
  }

  getEmergencyReports(){
    this.emergencyData = this.db.list('reports', ref => ref.orderByChild('case').equalTo(1));
    return this.emergencyData;
  }

  getLatestEmergencyReports(){
    this.latestEmergencyData = this.db.list('reports', ref => ref.orderByChild('case').equalTo(1).limitToFirst(3));
    return this.latestEmergencyData;
  }

  getLatestReports(){
    this.latestReportsData = this.db.list('reports', ref => ref.orderByChild('isReady').equalTo(true).limitToFirst(5));
    return this.latestReportsData;
  }

  getReportsFor(stationType:any){
    this.reportList = this.db.list('reports', ref => ref.orderByChild('stationType').equalTo(Number(stationType)));
    return this.reportList;
  }

  getReportDataFor(r:any){
    this.reportDetailIs = this.db.object('reports/' + r);
    return this.reportDetailIs;
  }

  generateReport(theTask:any):void{
    this.taskDetailIs.valueChanges().subscribe(data => {
      
      if(data['isActive']){
        this.reportTaskIs = data;
        this.stationDataIs.valueChanges().subscribe(sData => {
          this.reportStationIs = sData;

          this.reportList = this.db.list('reports');
          let theIdOfReport = Date.now();
          let theDate = new Date();
          let theAIResultText1 = "<p>Tespit edilen hasarlar:</p><ol><li>Kanatlarda 15cm ve üzeri çizikler</li><li>Kanatta kırılma</li><li>Kanatlardan birinde donma</li><li>Jeneratör bölümünde aşırı islenme ve kirlilik</li></ol>";
          let theAIResultText2 = "<p>Tespit edilen hasarlar:</p><ol><li>Kanatlarda 15cm ve üzeri çizikler</li><li>Kanatlardan birinde donma</li><li>Jeneratör bölümünde aşırı islenme ve kirlilik</li></ol>";
          let theAIResultText3 = "<p>Tespit edilen hasarlar:</p><ol><li>Jeneratör bölümünde aşırı islenme ve kirlilik</li></ol>";
          let theAIResultText4 = "Hiçbir hasar tespit edilmemiştir. Detaylı inceleme için canlı yayın yapılabilir.";
          let tomorrow = String(theDate.getDate() + 1).padStart(2, '0');
          let secondDay = String(theDate.getDate() + 2).padStart(2, '0');
          let thirdDay = String(theDate.getDate() + 3).padStart(2, '0');
          let fourthDay = String(theDate.getDate() + 4).padStart(2, '0');
          let fifthDay = String(theDate.getDate() + 5).padStart(2, '0');
          let month = String(theDate.getMonth() + 1).padStart(2, '0');
          let year = theDate.getFullYear();;
          let nextDate = tomorrow + '.' + month + '.' + year;
          let secondDate = secondDay + '.' + month + '.' + year;
          let thirdDate = thirdDay + '.' + month + '.' + year;
          let fourthDate = fourthDay + '.' + month + '.' + year;
          let fifthDate = fifthDay + '.' + month + '.' + year;
          let theCase = Math.floor(Math.random() * 4) + 1;
          let theAIResultText;
          let theReportImages;
          let efData1;
          let efData2;
          let efData3;
          let efData4;
          let efData5;
          if (theCase == 1) {
            theAIResultText = theAIResultText1;
            theReportImages = [
              {
                path: "images/DSCN0184.JPG"
              },
              {
                path: "images/DSCN0226.JPG"
              },
              {
                path: "images/DSCN0387.JPG"
              }
            ];
            efData1 = 92;
            efData2 = 86;
            efData3 = 77;
            efData4 = 64;
            efData5 = 56;
          } else if (theCase == 2) {
            theAIResultText = theAIResultText2;
            theReportImages = [
              {
                path: "images/DSCN0226.JPG"
              },
              {
                path: "images/DSCN0387.JPG"
              }
            ];
            efData1 = 92;
            efData2 = 87;
            efData3 = 82;
            efData4 = 76;
            efData5 = 69;

          } else if (theCase == 3) {
            theAIResultText = theAIResultText3;
            theReportImages = [
              {
                path: "images/DSCN0226.JPG"
              }
            ];
            efData1 = 93;
            efData2 = 90;
            efData3 = 86;
            efData4 = 81;
            efData5 = 76;


          } else if (theCase == 4) {
            theAIResultText = theAIResultText4;
            theReportImages = [];
            efData1 = 98;
            efData2 = 96;
            efData3 = 97;
            efData4 = 97;
            efData5 = 98;

          }
          this.reportList.push({
            id: theIdOfReport,
            aiDecisions: {
              comment: theAIResultText,
              efficienyRisk: [
                {
                  date: nextDate,
                  efficieny: efData1
                },
                {
                  date: secondDate,
                  efficieny: efData2
                },
                {
                  date: thirdDate,
                  efficieny: efData3
                },
                {
                  date: fourthDate,
                  efficieny: efData4
                },
                {
                  date: fifthDate,
                  efficieny: efData5
                }
              ]
            },
            case: theCase,
            date: theIdOfReport,
            droneId: this.reportTaskIs['droneId'],
            images: theReportImages,
            isReady: false,
            portId: this.reportTaskIs['portId'],
            reportLink: "",
            sound: "",
            stationId: this.reportTaskIs['stationId'],
            stationType: this.reportStationIs['type'],
            taskId: theTask,
            workTime: 0

          }).catch(e => {
            console.log(e);
          });
        });

      }
    });
    
  }

 
  getContent(path:any, dataParam:any, pageParam:any, infoParam:any, addParam:any){
    const pData = { d: ''+dataParam, p:pageParam, i:infoParam, a:addParam };
    console.log(path);
    const getHeaders = new HttpHeaders({'Sender': 'AIRCON', 'UID': this.authService.getUID});
    return this.http.get<any>(this.apiURL+'/'+path, {params:pData, headers:getHeaders});
  }

  formAction(path:any, formData:any){
    return this.http.post<any>(this.apiURL+'/'+path, formData, this.actionHeaders);
  }


  doAction(path:any, dataParam:any, pageParam:any, infoParam:any, addParam:any){
    const pData = { d: ''+dataParam, p:pageParam, i:infoParam, a:addParam };
    return this.http.post<any>(this.apiURL+'/'+path, pData, this.actionHeaders);
  }

}
