<div class="container air-login-base">
  <div class="row justify-content-center" id="loginFormBase">
    <div class="col-4 align-self-center air-login-box-base" id="loginBoxBase">
      <span class="logo-base">
        <i class="logo"></i>
        <b class="name">YÖNETİM KONSOLU</b>
      </span>
      <div class="air-white-box login-box">
        <div class="form-group">
          <label for="userEmail">E-Posta Adresiniz:</label>
          <input type="email" class="form-control" placeholder="ornek@isletme.com" #userEmail required>
        </div>
        <div class="form-group">
          <label for="userPassword">Şifreniz:</label>
          <input type="password" class="form-control" placeholder="Şifreniz" #userPassword required>
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-primary" (click)="authService.SignIn(userEmail.value, userPassword.value)"><i class="fas fa-fingerprint"></i> Giriş Yapın</button>
        </div>
      </div>
    </div>
  </div>
</div>