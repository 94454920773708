<div class="air-sidebar" id="sidebarBase">
    <div class="menu">
        <span class="menu-item" *ngFor="let menuItem of menuItems">
            <a  *ngIf="menuItem.canSee" routerLink="/{{menuItem.link}}" class="btn btn-link" [ngClass]="{'disabled': menuItem.canUse === false}" routerLinkActive="actived" [routerLinkActiveOptions]="{exact: false}">
                <i class="fas fa-{{menuItem.icon}}"></i> {{menuItem.title}}
            </a>
        </span>
        
    </div>
    <div class="console-info">
        <a href="https://auraarge.com/#/projects/auraair" target="_blank" class="air-logo"></a>
        <span class="version">v.{{c_version}}</span>
    </div>
</div>
