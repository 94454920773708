<div class="air-header" >
    <span class="logo-base align-middle">
        <a (click)="sidebar()" class="air-sidebar-btn header-btn" #sidebarBtn>
            <i class="fas fa-grip-lines"></i>
        </a>
        <a routerLink="/home" class="logo-btn" #sidebarBtn *ngIf="authService.userData as user">
            <img class="" src="{{(user.logoURL) ? user.logoURL : '/assets/img/default-logo.png'}}" alt="{{user.displayName}}">
        </a>
    </span>
    <span class="info-base align-middle" *ngIf="authService.userData as user">
        <i title="{{user.uid}}" class="user">{{user.email}}</i>
        <a class="air-logout header-btn" (click)="authService.SignOut()" title="Çıkış Yap">
            <i class="fas fa-power-off"></i>
        </a>
    </span>
</div>
