import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from "./auth.service";
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private http: HttpClient, public authService: AuthService, public api: ApiService) { }

  public isTaskActivated:boolean = true;
  public actionMessage:string = 'Görev gerçekleştiriliyor, lütfen bekleyiniz';

  public taskRoutes: any;
  public selectedPort: any;
  public selectedTurbine: any;
  public mapEvents: any;
  public isTaskMapSaved:boolean = false;

  run(taskId:any){
    const headers = { 'Sender': 'AIRCON', 'UID': this.authService.getUID};
    const pData = { task: taskId };
    return this.http.post<any>(this.api.apiURL+'/tasks/run', pData, {headers});
  }

  delete(taskId:any){
    const headers = { 'Sender': 'AIRCON', 'UID': this.authService.getUID};
    const pData = { task: taskId };
    return this.http.post<any>(this.api.apiURL+'/tasks/delete', pData, {headers});
  }

}
