<h2 mat-dialog-title *ngIf="data.title != ''">
    {{data.title}}
    <button type="button" class="close" (click)="close()" aria-label="Kapat" *ngIf="data.buttonText == '' && data.isClosable">
        <span aria-hidden="true">&times;</span>
    </button>
</h2>

<mat-dialog-content>
<div class="air-dialog-content" *ngIf="data.content != ''" [innerHTML]="data.content"></div>
<div id="livePlayer" *ngIf="data.isPlayerScreen">
    <div id="twitch-embed" class="air-live-player-base"></div>
</div>
<div id="youtubePlayer" class="text-center" *ngIf="data.isYoutubeScreen">
    <iframe width="1000" height="564" src="https://www.youtube-nocookie.com/embed/ucCKPgUKqwU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
</mat-dialog-content>
<!--<mat-dialog-actions *ngIf="data.isPlayerScreen">
    <button class="btn btn-danger" (click)="stopTask(data.id)" [disabled]="stopProcess">
        <span  *ngIf="!stopProcess">Görevi Durdur</span>
        <span  *ngIf="stopProcess"><i>Görev Sonlandırılıyor (<countdown #taskTimer [config]="{leftTime: 15, format: 's'}" (event)="countdownHandler($event)"></countdown>)</i></span>
        
    </button>
</mat-dialog-actions>-->
<mat-dialog-actions *ngIf="data.buttonText != ''">
    <button class="btn btn-outline-secondary" (click)="close()">Kapat</button>
    <button class="btn" [ngClass]="{'btn-success': data.action === 'run', 'btn-danger':data.action === 'del' }" (click)="confirm(data.action, data.id, data.issued)">{{data.buttonText}}</button>
</mat-dialog-actions>
