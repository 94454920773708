import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from "@angular/material/dialog";
import {MatButtonModule} from '@angular/material/button';
import { TasksService } from '../../../services/tasks.service';
import { ApiService } from '../../../services/api.service';
import { CountdownModule } from 'ngx-countdown';
import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';
const dialogConfig = new MatDialogConfig();
declare const  createTwitchPlayer: any;
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  result: any = [];
  constructor(public dialogRef: MatDialogRef<ModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any, public taskApi: TasksService, public matDialog: MatDialog, public api: ApiService) { }
  stopProcess:boolean = false;
  ngOnInit(): void {
  }

  public createModal(mTitle:any, mContent:any, mAction:any, mButtonText:any, mData:any, mClosable:boolean, mPlayer:boolean){
    dialogConfig.data = {
      title: mTitle,
      content: mContent,
      action: mAction,
      buttonText: mButtonText,
      isClosable: mClosable,
      id: mData,
      isPlayerScreen: mPlayer
    };
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    let dialogRef = this.matDialog.open(ModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
      console.log(`Dialog sent: ${value}`); 
    });
  }

  public playerModal(pid){
    dialogConfig.data = { 
      title: 'Canlı Yayın Görevi',
      content: '',
      action: '',
      buttonText: '',
      isClosable: true,
      id: pid,
      isPlayerScreen: true
    };
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    let dialogRef = this.matDialog.open(ModalComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(value => {
      createTwitchPlayer();
    });
    dialogRef.afterClosed().subscribe(value => {
      console.log('Closed');
      //this.taskApi.isTaskActivated = false;
    });
  }

  public youtubeModal(){
    dialogConfig.data = { 
      title: 'Canlı Yayın Görevi',
      content: '',
      action: '',
      buttonText: '',
      isClosable: true,
      id: 'reportYoutube',
      isYoutubeScreen: true
    };
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    let dialogRef = this.matDialog.open(ModalComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(value => {
      console.log('Youtube Initialized');
    });
    dialogRef.afterClosed().subscribe(value => {
      console.log('Closed');
    });
  }

  stopTask(taskId:any){
    this.stopProcess = true;
  }

  countdownHandler(e){
    console.log(e.action);
    if(e.action === 'done'){
      this.stopProcess = true;
      this.close();
    }
  }

  public successModal(feedback:any){
    var theTitle = 'İşlem Başarılı';
    var theContent = '<div class="air-feedback-base"><span class="feedback-icon"><div class="swal2-icon swal2-success swal2-animate-success-icon" style="display: flex;"><div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div><span class="swal2-success-line-tip"></span><span class="swal2-success-line-long"></span><div class="swal2-success-ring"></div><div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div><div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div></div></span><span class="feedback-text">'+feedback+'</span></div>';
    this.createModal(theTitle, theContent, '', '', '', true, false);
  }

  public errorModal(feedback:any){
    var theTitle = 'İşlem Başarısız!';
    var theContent = '<div class="air-feedback-base"><span class="feedback-icon"><div class="swal2-icon swal2-error swal2-animate-error-icon" style="display: flex;"><span class="swal2-x-mark"><span class="swal2-x-mark-line-left"></span><span class="swal2-x-mark-line-right"></span></span></div></span><span class="feedback-text">'+feedback+'</span></div>';
    this.createModal(theTitle, theContent, '', '', '', true, false);
  }
  
  close() {
    this.dialogRef.close();
  }

  confirm(act:any, id:any, issued:any){
    if(act=='run'){
      this.runTask(id, issued);
    }else if(act=='del'){
      this.deleteTask(id);
    }
  }

  runTask(taskId:any, taskIssued:any){
    this.dialogRef.close();
    let runAct = this.api.runTask(taskId);
    if(runAct){
      this.taskApi.isTaskActivated = true;
      this.api.generateReport(taskId);
      this.playerModal(taskId);
      /*if(taskIssued == 2){
        this.playerModal(taskId);
      }else{
        this.successModal('Görev başarı ile çalıştırıldı. Tamamlandığında bilgi verilecektir.');
      }*/
    }else{
      this.errorModal(runAct);
    }
  }

  deleteTask(taskId:any){
    this.dialogRef.close();
    let delAct = this.api.delTask(taskId);
    if(delAct){
      this.successModal('Görev başarı ile silinmiştir.');
        setTimeout(function(){
          window.location.href = '/tasks';
        }, 5000);
    }else if(!delAct){
      this.errorModal('Bir hata oluştu lütfen daha sonra tekrar deneyiniz.');
    }
  }
}
