import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { AuthService } from "../../../services/auth.service";
import {Menu} from "src/app/interfaces/menu";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: Menu[];
  constructor(private api: ApiService, private authService: AuthService) { }
  //socket = io('http://localhost:3001');
  c_version = '0.1.0'
  
  ngOnInit(): void {
    let s = this.api.getUserMenu(); 
    s.snapshotChanges().subscribe(data => { 
      this.menuItems = [];
      data.forEach(item => {
        let a = item.payload.toJSON(); 
        a['id'] = item.key;
        this.menuItems.push(a as Menu);
      })
    })
    //this.getMenuItems();
  }

  getMenuItems(){
    this.api.getContent('menu', '', '', '', '')
    .subscribe((data: any) => {
      this.menuItems = data;
    }, err => {
      console.log(err);
    });
  }
}
